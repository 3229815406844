import React, { Component, useEffect, useState } from "react";
import "./Landing.scss";
import { FormControl, FormControlLabel, Checkbox, Button, TextField, Typography, Link } from '@material-ui/core';
import { navigate } from 'gatsby';
import LoadingBar from 'react-top-loading-bar';
import JwModal from 'jw-react-modal';
import TandCPage from '../Terms/terms';
import TandCPageMy from '../Terms/termsMy';
import { isEmpty } from "lodash";
import Card from "react-md/lib/Cards/Card";
import "../style.scss";
import { Alert } from "react-bootstrap";
import {
  END_POINT_CUSTOMER_ON_BOARD,
  END_POINT_GET_QUOTE,
  API_URL,
  API_AUTH_TOKEN_URL,
  API_AUTH_TOKEN_USERNAME,
  API_AUTH_TOKEN_USERPASS,
  API_DEVICE_INFO_URL
} from "gatsby-env-variables";

class Landing extends Component {

  inputRef = {
    customerName: React.createRef(),
    email: React.createRef(),
    contactNo: React.createRef(),
    termsAndCond: React.createRef(),
    storeName: React.createRef(),
    invoiceNo: React.createRef(),
    newDeviceIMEI: React.createRef(),
    newDeviceMakeAndModel: React.createRef(),
    newDeviceSRP: React.createRef(),
    token: React.createRef(),
    scPackage: React.createRef(),
  }
  constructor(props) {
    super(props);
    this.invalidFields = [];
  }

  state = {
    customerName: '',
    email: '',
    contactNo: '',
    tradeInId: '',
    termsAndCond: false,
    promotion: true,
    storeName: '',
    imei: '',
    manufacturer: '',
    model: '',
    invoiceNo: '',
    newDeviceIMEI: '',
    newDeviceMakeAndModel: '',
    newDeviceSRP: '',
    token: '',
    realm: '',
    pickUpAddress: '',

    customerNameError: '',
    emailError: '',
    contactNoError: '',
    termsAndCondError: '',
    showInvalidTradeIDMessage: false,
    loading: true,
    showDuplicateTradeIDMessage: false,
    invoiceNoError: '',
    newDeviceIMEIError: '',
    newDeviceSRPError: '',
    tokenError: '',
    pickUpAddressError: '',
    scPackage: false,
  }
  fakeRequest = () => {
    return new Promise(resolve => setTimeout(() => resolve(), 1000));
  };
  componentDidMount() {

    const { customerOnBoarding } = this.props;

    let params = new URLSearchParams(document.location.search.substring(1));
    let tradeinIdParam = params.get("TradeID");
    let realm = params.get("realm");
    realm = (!realm) ? 'PH' : realm?.toUpperCase();
    var tradeInId = (!tradeinIdParam) ? customerOnBoarding.tradeInId : tradeinIdParam;
    if (!tradeInId) {
      navigate("/404");
    }

    this.getQuote(tradeInId);

    this.setState({
      customerName: customerOnBoarding.customerName,
      email: customerOnBoarding.email,
      contactNo: customerOnBoarding.contactNo,
      tradeInId: tradeInId,
      termsAndCond: customerOnBoarding.termsAndCond,
      promotion: customerOnBoarding.promotion,
      invoiceNo: customerOnBoarding.invoiceNo,
      newDeviceIMEI: customerOnBoarding.newDeviceIMEI,
      newDeviceMakeAndModel: customerOnBoarding.newDeviceMakeAndModel,
      storeEmail: customerOnBoarding.storeEmail,
      newDeviceSRP: customerOnBoarding.newDeviceSRP,
      token: customerOnBoarding.token,
      pickUpAddress: customerOnBoarding.pickUpAddress,
      realm: realm
    });

    this.fakeRequest().then(() => {
      const el = document.querySelector(".loader");
      if (el) {
        el.remove();  // removing the spinner element
        this.setState({ loading: false }); // showing the app
      }
    });
  }

  getQuote = async (tradeId) => {
    try {

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic YmxhbmNjb3Byb2QyMDo4K0tQNWguMz1iKQ=='
        },
      };
      await fetch(API_URL + END_POINT_GET_QUOTE + `?trade_id=${tradeId}`, requestOptions)
        .then(response => response.json())
        .then((response) => {
          if (response.responseCode == 'OK') {
            if (response.responseLine.ErrorCode == '205') {
              this.setState({ showInvalidTradeIDMessage: true });
            } else {
              this.setState({
                carrier: response.responseLine.Device.Carrier,
                displayName: response.responseLine.Device.DisplayName,
                imei: response.responseLine.Device.Imei,
                manufacturer: response.responseLine.Device.Manufacturer,
                model: response.responseLine.Device.Model,
                serial: response.responseLine.Device.Serial,
                storage: response.responseLine.Device.Storage,
                storeName: response.responseLine.Device.StoreName,
                storeEmail: response.responseLine.Device.StoreEmail,
                tagCode: response.responseLine.Device.TagCode,
                price: response.responseLine.Price,
                currency: response.responseLine.Currency,
              })
            }
          } else {
            this.setState({ showInvalidTradeIDMessage: true });
            console.log("500 Error");
          }
        }).catch(err => console.log(err)

        )
    } catch {
      err => console.log(err)
    }
  }

  getAuthToken = () => {
    try { //try to get data
      return new Promise(resolve => {
        let objTokenRequest = {
          userName: API_AUTH_TOKEN_USERNAME,
          password: API_AUTH_TOKEN_USERPASS
        }

        const requestOptionsToken = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(objTokenRequest)
        };

        fetch(API_AUTH_TOKEN_URL, requestOptionsToken)
          .then(response => response.json())
          .then((response) => {
            if (response.status === 'SUCCESS') {
              resolve(response.jwtToken.accessToken.trim());
            }

          }).catch(err => console.log(err));

      });
    } catch {
      err => console.log(err)
    }

  }


  getNewDeviceMakeAndModel = () => {
    let newDeviceIMEI = this.state.newDeviceIMEI;
    if (newDeviceIMEI.length < 15) {
      return false;
    }
    var that = this;

    try {
      this.getAuthToken().then(function (response) {
        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': response
          },
        };

        fetch(API_DEVICE_INFO_URL + newDeviceIMEI + `?countryCode=` + that.state.realm, requestOptions)
          .then(response => response.json())
          .then((response) => {

            if (response.status == 'OK' && response.obj.length > 0) {
              let res = response.obj[0];
              that.setState({
                newDeviceMakeAndModel: res.tacMake.trim() + ' ' + res.tacModel2.trim()
              })
            } else {
              that.setState({
                newDeviceMakeAndModel: 'No make & model present'
              })
            }
          }).catch(err => console.log(err))
      });
    } catch {
      err => console.log(err)
    }
  }


  saveForm = () => {
    this.setState({ showDuplicateTradeIDMessage: false });
    if (this.vaildateFields()) {
      this.LoadingBar.continuousStart();

      let customerObj = {
        customerName: this.state.customerName,
        email: this.state.email,
        contactNo: this.state.contactNo,
        termsAndCond: this.state.termsAndCond,
        promotion: this.state.promotion,
        storeName: this.state.storeName,
        serialNumber: this.state.serial,
        deviceStorage: this.state.storage,
        carrier: this.state.carrier,
        tagCode: this.state.tagCode,
        displayName: this.state.displayName,
        phoneModel: this.state.model,
        manufacturer: this.state.manufacturer,
        tradeInId: this.state.tradeInId,
        phoneImei: this.state.imei,
        tradeInValue: this.state.price,
        invoiceNo: this.state.invoiceNo,
        newDeviceIMEI: this.state.newDeviceIMEI,
        newDeviceMakeAndModel: this.state.newDeviceMakeAndModel,
        storeEmail: this.state.storeEmail,
        newDeviceSRP: this.state.newDeviceSRP,
        token: this.state.token,
        pickUpAddress: this.state.pickUpAddress || '',
        countryCode: this.state.realm,
        scPackage: this.state.scPackage
      }

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic YmxhbmNjb3Byb2QyMDo4K0tQNWguMz1iKQ=='
        },
        body: JSON.stringify(customerObj)
      };
      fetch(API_URL + END_POINT_CUSTOMER_ON_BOARD, requestOptions)
        .then(response => response.json())
        .then((response) => {
          this.LoadingBar.complete();
          if (response.responseCode == 'OK' && response.responseLine != null) {
            navigate("/thanks");
          } else {

            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });

            this.setState({ showDuplicateTradeIDMessage: true });
          }
        }).catch(err => this.LoadingBar.complete()

        )
    }
  }

  handleInputRestrictions = (e) => {

    let invalidEntry = false
    const numericOnly = /^[0-9 +\b]+$/;
    const alphaNumericOnly = /[0-9a-zA-Z +\b]+$/;

    switch (e.target.name) {
      case "customerName":
        if (e.target.value !== '') {
          invalidEntry = false;
          e.target.value = (e.target.value).toUpperCase();
        }
        break;
      case "email":
        e.target.value = (e.target.value).toLowerCase()
        break;
      case "contactNo":
        if (!(e.target.value === '' || numericOnly.test(e.target.value)))
          invalidEntry = true
        else if (e.target.value.split('')[this.state.contactNo.length] === '+' && this.state.contactNo.length)
          invalidEntry = true
        else if (e.target.value.split('')[0] === '0')
          e.target.value = this.state.realm === "MY" ? '+60' : '+639';
        break;
      case "invoiceNo":
        if ((e.target.value !== '' || alphaNumericOnly.test(e.target.value))) {
          invalidEntry = false
          e.target.value = (e.target.value).toUpperCase();
        }
        else
          invalidEntry = true
        break;
      case "newDeviceIMEI":
      case "newDeviceSRP":
      case "token":
        if (!(e.target.value === '' || numericOnly.test(e.target.value)))
          invalidEntry = true
        break;
      default:
        break;
    }
    if (!invalidEntry) {
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }

  handleContactNoFormat = (e) => {
    const phoneCode1 = e.target.value.split('').splice(0, 3).join('');
    const phoneCode2 = e.target.value.split('').splice(0, 2).join('');
    const phoneLength = e.target.value.length;
    if (this.state.realm === "MY" && phoneCode1 !== '+60' && (phoneLength === 9 || phoneLength === 10)) {
      this.setState({ contactNo: '+60' + e.target.value })
    } else if (this.state.realm === "MY" && phoneCode2 === '60' && (phoneLength === 11 || phoneLength === 12)) {
      this.setState({ contactNo: '+' + e.target.value })
    } else if (this.state.realm === "PH" && phoneCode1 !== '+63' && phoneLength === 10) {
      this.setState({ contactNo: '+63' + e.target.value })
    } else if (this.state.realm === "PH" && phoneCode2 === '63' && phoneLength === 12) {
      this.setState({ contactNo: '+' + e.target.value })
    }
  }

  vaildateFields = () => {
    let isValid = true;
    this.invalidFields = [];

    if (!this.state.customerName.trim()) {
      this.setState({
        customerNameError: "Your full name is required"
      })
      isValid = false;
      this.invalidFields.push(this.inputRef.customerName)
    } else this.setState({ customerNameError: '' })

    if (!this.state.email) {
      this.setState({
        emailError: "Email is required"
      })
      isValid = false;
      this.invalidFields.push(this.inputRef.email)
    } else if (!this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      this.setState({
        emailError: "Please enter a valid email address"
      })
      isValid = false;
      this.invalidFields.push(this.inputRef.email)
    } else this.setState({ emailError: '' })

    const phoneCode = this.state.realm === "MY" ? '+601' : '+639';
    if (!this.state.contactNo) {
      this.setState({
        contactNoError: "Mobile number is required"
      })
      this.invalidFields.push(this.inputRef.contactNo)
      isValid = false;
    } else if (this.state.realm === "MY" && (this.state.contactNo.length < (12) || this.state.contactNo.length > (13))) {
      isValid = false;
      this.setState({
        contactNoError: "Mobile number invalid. It needs to be at least 9 or 10 digits."
      })
      this.invalidFields.push(this.inputRef.contactNo)
    } else if (this.state.realm === "MY" && this.state.contactNo.split('').splice(0, 3).join('') !== '+60') {
      isValid = false;
      this.setState({
        contactNoError: "Mobile number invalid. It should start with +60."
      })
      this.invalidFields.push(this.inputRef.contactNo)
    } else if (this.state.realm === "PH" && this.state.contactNo.length !== 13) {
      isValid = false;
      this.setState({
        contactNoError: "Mobile number invalid. It needs to be at least 10 digits."
      })
      this.invalidFields.push(this.inputRef.contactNo)
    } else if (this.state.realm === "PH" && this.state.contactNo.split('').splice(0, 4).join('') !== '+639') {
      isValid = false;
      this.setState({
        contactNoError: "Mobile number invalid. It should start with +639."
      })
      this.invalidFields.push(this.inputRef.contactNo)
    }
    else this.setState({ contactNoError: '' })

    if (this.state.realm === "PH") {
      if (!this.state.invoiceNo) {
        this.setState({
          invoiceNoError: "Invoice/OR number is required"
        })
        isValid = false;
        this.invalidFields.push(this.inputRef.invoiceNo)
      }
      else if (this.state.invoiceNo.length < 4) {
        isValid = false;
        this.setState({
          invoiceNoError: "Invoice number invalid. It needs to be atleast 4 digits."
        })
        this.invalidFields.push(this.inputRef.invoiceNo)
      } else this.setState({ invoiceNoError: '' })


      if (!this.state.newDeviceIMEI) {
        this.setState({
          newDeviceIMEIError: "New Device IMEI or Serial Number is required"
        })
        isValid = false;
        this.invalidFields.push(this.inputRef.newDeviceIMEI)
      }
      else if (this.state.newDeviceIMEI.length !== 15) {
        isValid = false;
        this.setState({
          newDeviceIMEIError: "New Device IMEI or Serial Number invalid. It needs to be 15 digits."
        })
        this.invalidFields.push(this.inputRef.invoiceNo)
      }
      else this.setState({ newDeviceIMEIError: '' })

      if (!this.state.newDeviceSRP.trim()) {
        this.setState({
          newDeviceSRPError: "New Device SRP is required"
        })
        isValid = false;
        this.invalidFields.push(this.inputRef.newDeviceSRP)
      } else this.setState({ newDeviceSRPError: '' })

      if (!this.state.token.trim()) {
        this.setState({
          tokenError: "Token is required"
        })
        isValid = false;
        this.invalidFields.push(this.inputRef.token)
      } else this.setState({ tokenError: '' })
    }

    if (this.state.realm === "MY" && !this.state.pickUpAddress.trim()) {
      this.setState({
        pickUpAddressError: "Pick up address is required"
      })
      isValid = false;
      this.invalidFields.push(this.inputRef.pickUpAddress)
    }
    else this.setState({ pickUpAddressError: '' })

    if (!this.state.termsAndCond) {
      this.setState({
        termsAndCondError: "Please confirm that you accept the Terms and Conditions"
      })
      isValid = false;
      this.invalidFields.push(this.inputRef.termsAndCond)
    } else this.setState({ termsAndCondError: '' })

    return isValid;
  }

  checkTermsCheckBox = () => {
    this.setState({ termsAndCond: true })
  }

  render() {
    if (this.state.loading) {
      return null; //app is not ready (fake request is in process)
    }

    const TandC = (
      <span className="TandC">
        I agree to the <span className="link" onClick={JwModal.open('jw-modal-tandc')}>Terms and Conditions</span>
      </span>
    );
    return (
      <div className="index-wrapper">
        <LoadingBar onRef={ref => (this.LoadingBar = ref)} />

        <JwModal id="jw-modal-tandc">
          {(this.state.realm === "MY") ?
            <TandCPageMy userName={this.state.customerName} Model={this.state.model} Imei={this.state.imei} Storage={this.state.storage} StoreName={this.state.storeName} Manufacturer={this.state.manufacturer} TagCode={this.state.TagCode} Carrier={this.state.carrier} DisplayName={this.state.displayName} Serial={this.state.serial} Price={this.state.price} Currency={this.state.currency}></TandCPageMy>
            : <TandCPage userName={this.state.customerName} Model={this.state.model} Imei={this.state.imei} Storage={this.state.storage} StoreName={this.state.storeName} Manufacturer={this.state.manufacturer} TagCode={this.state.TagCode} Carrier={this.state.carrier} DisplayName={this.state.displayName} Serial={this.state.serial} Price={this.state.price} Currency={this.state.currency}></TandCPage>}

          <Button
            type="button"
            variant="contained"
            color="primary"
            size="small"
            fullWidth={false}
            style={{ marginRight: '10px' }}
            onClick={JwModal.close('jw-modal-tandc')}
            onMouseEnter={this.checkTermsCheckBox}
          >Agree to Terms and Conditions</Button>

          <Button
            variant="contained"
            color="primary"
            size="small"
            fullWidth={false}
            onClick={JwModal.close('jw-modal-tandc')}
          >Close</Button>
        </JwModal>
        {this.state.showInvalidTradeIDMessage &&
          <Alert variant="danger" transition>
            <Alert.Heading>
              <div className="flash isa_error"><i className="fa fa-times-circle"></i>&nbsp;&nbsp;<strong>Supplied TradeIn ID [{this.state.tradeInId}] is invalid</strong></div>
            </Alert.Heading>
          </Alert>
        }
        {this.state.showDuplicateTradeIDMessage &&
          <Alert variant="danger">
            <Alert.Heading>
              <div className="flash isa_error"><i className="fa fa-times-circle"></i>&nbsp;&nbsp;<strong>Device already traded-in</strong></div>
            </Alert.Heading>
          </Alert>
        }

        <div className="md-grid mobile-fix">
          <Card className="md-grid md-cell-14" >
            <Typography component="h1" variant="h5">
              <span className="from-sub-title">Fill up your details</span>
            </Typography>
            <FormControl>
              <TextField
                inputProps={{
                  maxLength: 50,
                }}
                ref={this.inputRef.customerName}
                value={this.state.customerName}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="customerName"
                label="Name"
                name="customerName"
                autoComplete="off"
                autoFocus
                onChange={this.handleInputRestrictions}
                className={`form-control ${this.state.customerNameError ? "invalid-control" : ""}`}
              />
              <label className="error">{this.state.customerNameError}</label>

              <TextField
                inputProps={{
                  maxLength: 50,
                }}
                value={this.state.email}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="off"
                onChange={this.handleInputRestrictions}
                className={`form-control ${this.state.emailError ? "invalid-control" : ""}`}

              />
              <label className="error">{this.state.emailError}</label>

              <TextField
                inputProps={{
                  maxLength: 15,
                }}
                value={this.state.contactNo}
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth
                id="contactNo"
                label="Contact No"
                name="contactNo"
                autoComplete="off"
                placeholder={this.state.realm === "MY" ? "e.g +601061234567" : "e.g +639061234567"}
                onChange={this.handleInputRestrictions}
                onBlur={this.handleContactNoFormat}
                className={`form-control ${this.state.contactNoError ? "invalid-control" : ""}`}
              />
              <label className="error">{this.state.contactNoError}</label>


              {this.state.realm === "PH" && <>
                <TextField
                  inputProps={{
                    maxLength: 100,
                  }}
                  value={this.state.storeName}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="storeName"
                  label="Store Name"
                  name="storeName"
                  autoComplete="off"
                  disabled
                />


                <TextField
                  inputProps={{
                    maxLength: 10,
                  }}
                  value={this.state.invoiceNo}
                  variant="outlined"
                  margin="normal"
                  required={true}
                  fullWidth
                  id="invoiceNo"
                  name="invoiceNo"
                  label="invoice No"
                  autoComplete="off"
                  onChange={this.handleInputRestrictions}
                  className={`form-control ${this.state.invoiceNoError ? "invalid-control" : ""}`}
                />
                <label className="error">{this.state.invoiceNoError}</label>


                <TextField
                  inputProps={{
                    maxLength: 15,
                  }}
                  value={this.state.newDeviceIMEI}
                  variant="outlined"
                  margin="normal"
                  required={true}
                  fullWidth
                  id="newDeviceIMEI"
                  name="newDeviceIMEI"
                  label="New Device IMEI or Serial Number"
                  autoComplete="off"
                  onChange={this.handleInputRestrictions}
                  onBlur={this.getNewDeviceMakeAndModel}
                  className={`form-control ${this.state.newDeviceIMEIError ? "invalid-control" : ""}`}
                />
                <label className="error">{this.state.newDeviceIMEIError}</label>

                <TextField
                  inputProps={{
                  }}
                  value={this.state.newDeviceMakeAndModel}
                  variant="outlined"
                  margin="normal"
                  required={true}
                  fullWidth
                  id="newDeviceMakeAndModel"
                  name="newDeviceMakeAndModel"
                  label='New Device Make &amp; Model'
                  autoComplete="off"
                  disabled
                />

                <TextField
                  inputProps={{
                    maxLength: 100
                  }}
                  value={this.state.storeEmail}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="storeEmail"
                  label="Store Email"
                  name="storeEmail"
                  autoComplete="off"
                  disabled
                />

                <TextField
                  inputProps={{
                    maxLength: 10,
                  }}
                  value={this.state.newDeviceSRP}
                  variant="outlined"
                  margin="normal"
                  required={true}
                  fullWidth
                  id="newDeviceSRP"
                  name="newDeviceSRP"
                  label="New Device SRP"
                  autoComplete="off"
                  onChange={this.handleInputRestrictions}
                  className={`form-control ${this.state.newDeviceSRPError ? "invalid-control" : ""}`}
                />
                <label className="error">{this.state.newDeviceSRPError}</label>

                <TextField
                  inputProps={{
                    maxLength: 10,
                  }}
                  value={this.state.token}
                  variant="outlined"
                  margin="normal"
                  required={true}
                  fullWidth
                  id="token"
                  name="token"
                  label="Token"
                  autoComplete="off"
                  onChange={this.handleInputRestrictions}
                  className={`form-control ${this.state.tokenError ? "invalid-control" : ""}`}
                />
                <label className="error">{this.state.tokenError}</label>

                <div>
                <i>Get ADDITIONAL P2,500 Top-up for every Samsung Care+ enrolled on your Trade-up Device!</i>
                </div>

                <div className="check-box">
                  <FormControlLabel
                    control={<Checkbox
                      name="scPackage"
                      color="primary"
                      ref={this.inputRef.scPackage}
                      checked={this.state.scPackage}
                      onChange={() => this.setState(prevState => ({ scPackage: !prevState.scPackage }))}
                    />
                    }
                    label="SC+ Package"
                    className="check-box-txt"
                  />
                </div>
              </>}

              {this.state.realm === "MY" && <div><TextField
                inputProps={{
                  maxLength: 100
                }}
                value={this.state.pickUpAddress}
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth
                id="pickUpAddress"
                label="Pick Up Address"
                name="pickUpAddress"
                autoComplete="off"
                onChange={this.handleInputRestrictions}
                className={`form-control ${this.state.pickUpAddressError ? "invalid-control" : ""}`}
              />
                <label className="error">{this.state.pickUpAddressError}</label>
              </div>
              }

              <div className="check-box">
                <FormControlLabel
                  control={<Checkbox
                    name="termsAndCond"
                    color="primary"
                    disabled={true}
                    ref={this.inputRef.termsAndCond}
                    checked={this.state.termsAndCond}
                    onChange={() => this.setState(prevState => ({ termsAndCond: !prevState.termsAndCond }))}
                  />
                  }
                />
                <label>{TandC}</label>
                <label className="error">{this.state.termsAndCondError}</label>
              </div>


              <FormControlLabel
                control={<Checkbox
                  name="promotion"
                  color="primary"
                  ref={this.inputRef.promotion}
                  checked={this.state.promotion}
                  onChange={() => this.setState(prevState => ({ promotion: !prevState.promotion }))}
                />
                }
                label="I want to receive Marketing Promotional campaigns"
                className="check-box-txt"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                fullWidth={false}
                onClick={() => this.saveForm()}
                className="btn-submit"
                disabled={this.state.showInvalidTradeIDMessage}>

                <span className="btn-submit-txt">Submit</span> <i className="arrow-right right"></i>
              </Button>
            </FormControl>
          </Card>
        </div>
      </div>
    );
  }
}

export default Landing;
